import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7a4cfad4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navigation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: "/domains",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('pageChanged')))
    }, {
      default: _withCtx(() => [
        _createTextVNode("Domains")
      ]),
      _: 1
    }),
    (_ctx.isAdmin)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: "/problems",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('pageChanged')))
        }, {
          default: _withCtx(() => [
            _createTextVNode("DNS data")
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.loggedIn)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          to: "/logout",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('pageChanged')))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Logout")
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_router_link, {
          key: 2,
          to: "/login",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('pageChanged')))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Login")
          ]),
          _: 1
        }))
  ]))
}