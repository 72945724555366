import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-312453d5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "problempanel"
}
const _hoisted_2 = ["title"]
const _hoisted_3 = ["title"]
const _hoisted_4 = ["title"]
const _hoisted_5 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_prime_message = _resolveComponent("prime-message")!
  const _component_prime_column = _resolveComponent("prime-column")!
  const _component_prime_datatable = _resolveComponent("prime-datatable")!

  return (_ctx.isAdmin)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", null, [
          _createElementVNode("button", {
            class: "p-button",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.loadExcelFile && _ctx.loadExcelFile(...args)))
          }, "Download data")
        ]),
        (_ctx.loadingError)
          ? (_openBlock(), _createBlock(_component_prime_message, {
              key: 0,
              severity: "error",
              closable: false
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Error loading problems, please try again ")
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_prime_datatable, {
              key: 1,
              value: _ctx.domains,
              loading: _ctx.loading,
              "data-key": "name",
              scrollable: true,
              "scroll-height": "flex",
              class: "domaintable",
              paginator: false,
              lazy: true,
              "total-records": _ctx.totalRecords,
              "responsive-layout": "scroll",
              "current-page-report-template": "Showing {first} to {last} of {totalRecords}"
            }, {
              empty: _withCtx(() => [
                _createTextVNode(" No problems found.")
              ]),
              loading: _withCtx(() => [
                _createTextVNode(" Loading problems. Please wait.")
              ]),
              default: _withCtx(() => [
                _createVNode(_component_prime_column, {
                  field: "domainName",
                  header: "Domain"
                }, {
                  body: _withCtx(({ data }) => [
                    _createElementVNode("span", null, _toDisplayString(data.domainName), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_prime_column, {
                  field: "creationDate",
                  header: "Created"
                }, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_ctx.formattedDate(data.created)), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_prime_column, {
                  header: "IP",
                  field: "ip",
                  "show-filter-match-modes": false,
                  "show-filter-menu": false
                }, {
                  body: _withCtx(({ data }) => [
                    _createElementVNode("span", null, _toDisplayString(data.ip), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_prime_column, {
                  header: "Validation record - name",
                  field: "validationRecordName",
                  "show-filter-match-modes": false,
                  "show-filter-menu": false
                }, {
                  body: _withCtx(({ data }) => [
                    _createElementVNode("span", null, _toDisplayString(data.validationRecord.name), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_prime_column, {
                  header: "Validation record - type",
                  field: "validationRecordType",
                  "show-filter-match-modes": false,
                  "show-filter-menu": false
                }, {
                  body: _withCtx(({ data }) => [
                    _createElementVNode("span", null, _toDisplayString(data.validationRecord.type), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_prime_column, {
                  header: "Validation record - value",
                  field: "validationRecordValue",
                  "show-filter-match-modes": false,
                  "show-filter-menu": false
                }, {
                  body: _withCtx(({ data }) => [
                    _createElementVNode("span", null, _toDisplayString(data.validationRecord.value), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_prime_column, {
                  "filter-field": "dnsValid",
                  header: "Domain Ip Validation",
                  class: "dns-status-col"
                }, {
                  body: _withCtx(({ data }) => [
                    (data.domainIpValidationStatus.valid === _ctx.ValidationStatus.VALID)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: "pi pi-check dns-status dns-status--valid",
                          title: _ctx.composeCheckedMessage(data.domainIpValidationStatus)
                        }, null, 8, _hoisted_2))
                      : (_openBlock(), _createElementBlock("span", {
                          key: 1,
                          class: "pi pi-times dns-status dns-status--invalid",
                          title: _ctx.composeCheckedMessage(data.domainIpValidationStatus)
                        }, null, 8, _hoisted_3))
                  ]),
                  _: 1
                }),
                _createVNode(_component_prime_column, {
                  "filter-field": "recordValid",
                  header: "Validation Record Validation",
                  class: "dns-status-col"
                }, {
                  body: _withCtx(({ data }) => [
                    (data.validationRecordStatus.valid === _ctx.ValidationStatus.VALID)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: "pi pi-check dns-status dns-status--valid",
                          title: _ctx.composeCheckedMessage(data.validationRecordStatus)
                        }, null, 8, _hoisted_4))
                      : (_openBlock(), _createElementBlock("span", {
                          key: 1,
                          class: "pi pi-times dns-status dns-status--invalid",
                          title: _ctx.composeCheckedMessage(data.domainIpValidationStatus)
                        }, null, 8, _hoisted_5))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value", "loading", "total-records"]))
      ]))
    : _createCommentVNode("", true)
}