import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-f826b50a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "api-key p-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_prime_button = _resolveComponent("prime-button")!
  const _component_prime_column = _resolveComponent("prime-column")!
  const _component_prime_datatable = _resolveComponent("prime-datatable")!
  const _directive_styleclass = _resolveDirective("styleclass")!

  return (_ctx.value._embedded.content.length > 0)
    ? (_openBlock(), _createBlock(_component_prime_datatable, {
        key: 0,
        value: _ctx.value._embedded.content
      }, {
        empty: _withCtx(() => [
          _createTextVNode(" No ApiKeys defined.")
        ]),
        default: _withCtx(() => [
          _createVNode(_component_prime_column, {
            field: "apiKey",
            header: "Key",
            style: {"width":"12rem"}
          }, {
            body: _withCtx(({ data }) => [
              _withDirectives(_createVNode(_component_prime_button, {
                class: "p-button-rounded",
                icon: "pi pi-eye"
              }, null, 512), [
                [_directive_styleclass, { selector: '@next', toggleClass: 'p-hidden' }]
              ]),
              _createElementVNode("span", _hoisted_1, [
                _createElementVNode("span", null, _toDisplayString(data.apiKey), 1)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_prime_column, {
            field: "created",
            header: "Created"
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.formattedDate(data.created)), 1)
            ]),
            _: 1
          }),
          (_ctx.editable)
            ? (_openBlock(), _createBlock(_component_prime_column, {
                key: 0,
                exportable: false,
                class: "row-actions"
              }, {
                header: _withCtx(() => [
                  (_ctx.value._links.add)
                    ? (_openBlock(), _createBlock(_component_prime_button, {
                        key: 0,
                        class: "p-button-rounded",
                        icon: "pi pi-plus",
                        title: "Add Api Key",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addKey()))
                      }))
                    : _createCommentVNode("", true)
                ]),
                body: _withCtx(({ data }) => [
                  _createVNode(_component_prime_button, {
                    disabled: !data._links.delete,
                    class: "p-button-rounded p-button-success",
                    icon: "pi pi-trash",
                    title: "Remove Api Key",
                    onClick: ($event: any) => (_ctx.deleteKey(data))
                  }, null, 8, ["disabled", "onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["value"]))
    : _createCommentVNode("", true)
}